import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutIntervalSafeMode = ({ image }) => {
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Interval Safe Mode</h1>
        <p1>
          Interval safe mode is a way of letting your phone stay unlocked with
          the display active without the worry of progressing to the next act!
          We've found it great if you have interval cues, want to keep Show
          StopWatch open, but don’t want to worry about where you hold your
          phone! When you are ready to move on, just enable the buttons and away
          you go!
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default AboutIntervalSafeMode
