import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutShowStructure = ({ image }) => {
  //   console.log("************", image)
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Show Structure</h1>
        <p1>
          Set the Show Structure, you can have as many Acts or Intervals as you
          like and in any order with any name! Simply tap what you need and set
          your interval durations.
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default AboutShowStructure
