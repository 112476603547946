import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutActs = ({ image }) => {
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Shows Stops</h1>
        <p1>
          Nobody ever wants to show stop, but if you do you'll be glad your
          running Show StopWatch! Simply press the ShowStop button in the top
          left, then we'll give you the time that you stopped and the time
          elapsed! Hit resume when you are ready to continue and well calculate
          everything else!
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default AboutActs
