import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const SharingPreShowCalls = ({ image }) => {
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Share Pre Show Call Times</h1>
        <p1>
          If you ever want to know the times of the calls just tap the "Call
          Times" button. The calls can then be copied and pasted to anywhere you
          like!
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default SharingPreShowCalls
