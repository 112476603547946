import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutActs = ({ image }) => {
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Acts</h1>
        <p1>
          As easy as it looks, tap Act Down when you are ready to move on. Start
          unlimited In-Act Timers, ever needed to time the length of a scene or
          feature, simply tap the "Start A Timer" button and away you go. You
          can also preview all times, Show Stops and Timers, this will calculate running and playing up to the current act.
          If you ever go forward by mistake all Act and Interval screens have a
          reset and a back button which can take you to where you left off!
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default AboutActs
