import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutStartAShow = ({ image }) => {
  return (
    <ImgItem>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
      <div className="textWrapper">
        <h1>{`House Open & Clearance`}</h1>
        <p1>
          Simply tap the relevant buttons to store the appropriate time!
          Couldn't be easier. When you tap it the button it displays the time of
          the event.
        </p1>
      </div>
    </ImgItem>
  )
}

export default AboutStartAShow
