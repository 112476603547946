import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"
import styled from "styled-components"
import media from "styled-media-query"

const AboutIntervalSafeMode = ({ image, secondImage }) => {
  return (
    <ImgItem>
      {/* <DoubleImageWrapper> */}
        <Img
          fluid={image.childImageSharp.fluid}
          alt="Image"
          className="image"
          //className="double-image"
        />
        {/* <Img
          fluid={secondImage.childImageSharp.fluid}
          alt="Image 2"
          className="double-image"
        /> 
         */}
      
      <div className="textWrapper">
        <h1>Show Times</h1>
        <p1>
          Let us take all the hassle of calculate show times, we automatically
          calculate the running and playing times and all running times of Acts
          and Intervals, it doesn't matter how many well add them up!
        </p1>

        <h1>Rounding</h1>
        <p1>
          You can round all the times within Show StopWatch, in settings you
          just select how accurate you need them to be.
        </p1>

        <h1>Show Stop</h1>
        <p1>
          If you Show Stop, we take the duration of the show stop off of the
          total playing time, but not the running time.
        </p1>
      </div>

      {/* </DoubleImageWrapper> */}
    </ImgItem>
   
  )
}

export default AboutIntervalSafeMode

const DoubleImageWrapper = styled.div`
  display: flex;
  flex-direction: column;


  ${media.greaterThan("medium")`
flex-direction:row;
`}

  .double-image {
    /* width: 45vw; */
width:100%;
max-width: 500px;
    ${media.greaterThan("medium")`
    width:25vw;
    `}
  }
`
