import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const PreviewFinalTimes = ({ image }) => {
  return (
    <ImgItem>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />

      <div className="textWrapper">
        <h1>Preview & Edit</h1>
        <p1>
          with Show StopWatch you are able to preview the current
          running, playing, in act timers and show stop duration while running a show. Simple tap
          the preview button to display an up to date calculation of your
          performce.

          To edit or adjust previously stored times just long press on the act or interval to change the clearance, start and end times.
        </p1>
      </div>
    </ImgItem>
  )
}

export default PreviewFinalTimes
