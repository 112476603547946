import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutSharing = ({ image }) => {
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Sharing & Show History</h1>
        <p1>
          Sharing times couldn’t be easier, you can share your show times via
          the iOS ShareSheet. You can also choose to include “In Act Timers” when sharing
          as well.
        </p1>

        <p1>
          All Previous performances are now stored as well so you can revist previous performance times.
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default AboutSharing
