import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutInterval = ({ image }) => {
  return (
    <ImgItem>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
      <div className="textWrapper">
        <h1>Interval</h1>
        <p1>
          Here you can see the time remaining, and the elapsed time of the
          interval. Once the time remaining of the has reached zero, it'll go
          red and start counting up. You can also get interval notifications,
          these will notify you "x" minutes before the five and beginners calls or create custom notifications for the interval.
          The time that the call happens and the amount of time before the call
          that you receive a notification are all configurable in settings.
        </p1>
      </div>
    </ImgItem>
  )
}

export default AboutInterval
