import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutCustomPreshowCalls = ({ image }) => {
  return (
    <ImgItem>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
      <div className="textWrapper">
        <h1>Customisable Notifications</h1>
        <p1>
          Don’t want the Beginners call 5 minutes before curtain up? With Show StopWatch you can
          change all call times to what ever time you would like them before the
          show. You can also customise a warning Notification before the call, if
          you want a warning 10 minutes before the Half Hour Call but 1
          minutes before the Five, Quarter & Beginners thats all possible!
        </p1>
      </div>
    </ImgItem>
  )
}

export default AboutCustomPreshowCalls
