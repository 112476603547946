import React from "react"
import Layout from "../components/layout/layout"
import styled from "styled-components"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import ShowStructure from "../components/aboutItems/about-show-structure"
import Acts from "../components/aboutItems/about-acts"
import StartAShow from "../components/aboutItems/about-start-a-show"
import ShowStops from "../components/aboutItems/about-show-stops"
import Interval from "../components/aboutItems/about-interval"
import IntervalSafeMode from "../components/aboutItems/about-interval-safe-mode"
import FinalTimes from "../components/aboutItems/about-final-times"
import Sharing from "../components/aboutItems/about-sharing"
import PreShowCalls from "../components/aboutItems/about-pre-show-calls"
import CustomCalls from "../components/aboutItems/about-custom-call-times"
import SharingCalls from "../components/aboutItems/about-sharing-pre-show-calls"
import PreviewFinalTimes from "../components/aboutItems/about-preview-final-times"

const About = props => {
  const images = props.data

  return (
    <>
      <SEO title="About" description="Read more about ShowStopWatch" />
      <Layout>
        <Container>
          <h1>How it Works</h1>

          <h3>Timing a show couldn't be easier</h3>
          <Seporator />
          <List>
            <ShowStructure image={images.showStructureImg} />
            <StartAShow image={images.startAShowImg} />
            <Acts image={images.actImg} />
            <Interval image={images.intervalImg} />
            <IntervalSafeMode image={images.intervalSaveModeImg} />
            <PreviewFinalTimes image={images.finalTimesImg} />
            <ShowStops image={images.showStopImg} />
            <FinalTimes
              image={images.finalInActTimerImg}
              // secondImage={images.finalInActTimerImg}
            />
            <Sharing image={images.messageImg} />
          </List>

          <h1>Notifications & Pre Show Calls</h1>
          <Seporator />
          <ol>
            <PreShowCalls image={images.preShowCallsImg} />
            <CustomCalls image={images.preShowNotificationImg} />
            <SharingCalls image={images.preShowCallTimesImg} />
          </ol>
        </Container>
      </Layout>
    </>
  )
}

export default About

const Seporator = styled.div`
  display: flex;
  background-color: #505050;
  height: 1.5px;
  margin: auto;
  float: center;
  width: 60%;
`

const List = styled.ul`
  margin: 0;
`

const Container = styled.div`
background: linear-gradient(90deg, #1a022b 0%, rgba(47,3,78,1) 100%);
  h1 {
    color: white;
    text-align: center;
    padding: 1.5em;
    padding-bottom: 0.5em;
  }

  h3 {
    color: white;
    text-align: center;
    font-weight: 180;
  }
`

export const query = graphql`
  query {
    actImg: file(relativePath: { eq: "act.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    copyingCallsImg: file(relativePath: { eq: "copying-calls.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    finalInActTimerImg: file(
      relativePath: { eq: "act.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    finalTimesImg: file(relativePath: { eq: "final-times.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    homeImg: file(relativePath: { eq: "home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    intervalSaveModeImg: file(relativePath: { eq: "interval-safe-mode.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    intervalImg: file(relativePath: { eq: "interval.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    messageImg: file(relativePath: { eq: "previous-shows.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    preShowCallTimesImg: file(relativePath: { eq: "pre-show-call-times.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    preShowCallsImg: file(relativePath: { eq: "pre-show-calls.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    preShowNotificationImg: file(
      relativePath: { eq: "pre-show-notifications.png" } #{ eq: "pre-show-notifications.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    previewFinalTimesImg: file(
      relativePath: { eq: "preview-final-times.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showStructureImg: file(relativePath: { eq: "show-structure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    showStopImg: file(relativePath: { eq: "showstop.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    startAShowImg: file(relativePath: { eq: "start-a-show.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
