import React from "react"
import Img from "gatsby-image"
import { ImgItem } from "./image-item"

const AboutPreShowCalls = ({ image }) => {
  return (
    <ImgItem>
      <div className="textWrapper">
        <h1>Pre Show Calls</h1>
        <p1>
          Strange show time? Weird Panto matinees? Well now you can receive
          notifications "x" minutes before the call! It couldn't be easier! Show
          StopWatch will give you all the relevant calls at the desired times,
          all call times and warning notifcations can be
          customised! Select a Show Time or add a new one, much like you would
          in Alarms....and thats it!
        </p1>
      </div>
      <Img fluid={image.childImageSharp.fluid} alt="Image" className="image" />
    </ImgItem>
  )
}

export default AboutPreShowCalls
