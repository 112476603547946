import styled from "styled-components"
import media from "styled-media-query"

export const ImgItem = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  justify-content: center;
  margin-left: 10em;
  margin-right: 10em;
  margin-bottom: 1rem;
  margin-top: 1rem;
  /* border: 2px solid red; */
  /* margin: auto; */
  /* text-align: center; */
  /* width: 50vw; */
  h1 {
    color: white;
    /* background-color: blue; */
    margin-bottom: 0.4em;
    margin-top: 0.7em;
  }

  p1 {
    color: #e0e0e0;
    font-weight: 250;
    margin: auto;

    /* border: 1px solid green; */
  }

  div {
    /* border: 1px solid blue; */
    margin: 8px;
  }

  .textWrapper {
    margin: auto;
    /* border: 1px solid green; */
    height: 100%;
    width: 100%;
    text-align: center;

    h1 {
      /* background-color: red; */
      padding: 0;
    }
  }

  .image {
    /* width: 100%; */
    width: 100vw;
    max-width: 500px ;

    ${media.greaterThan("medium")`
    margin: 4em;
    `}
  }

  .double-image {
    width: 100vw;
    padding: 0;
  }

  ${media.lessThan("medium")`
  /* border: 1px solid red; */
  align-items:center;
  margin-left:1em;
  margin-right:1em;

.double-image{
  order:-1;

}

  .image{
    
    /* width:100vw; */
    /* width:60% */
  }
  `};
`
